<script>
import {Bar} from "vue-chartjs";

export default {
  name: "BarChart",
  extends: Bar,
  props: {
    labels: {
      type: Array,
      default: () => ([]),
    },
    data: {
      type: Array,
      default: () => ([]),
    },
  },

  data() {
    return {
      gradient2: null
    };
  },
  mounted() {

    this.gradient2 = this.$refs.canvas
        .getContext("2d")
        .createLinearGradient(0, 0, 0, 450);


    this.gradient2.addColorStop(0, "rgba(0, 231, 255, 0.4)");
    this.gradient2.addColorStop(0.5, "rgba(0, 231, 255, 0.25)");
    this.gradient2.addColorStop(1, "rgba(0, 231, 255, 0)");

    this.renderChart(
        {
          labels: this.labels,
          datasets: [
            {

              borderColor: "#05CBE1",
              pointBackgroundColor: "white",
              pointBorderColor: "white",
              borderWidth: 1,
              grid: false,
              backgroundColor: this.gradient2,
              data: this.data,

            }
          ]
        },
        {
          responsive: true,
          maintainAspectRatio: false,
          legend: false,
          scales: {
            yAxes: [{
              ticks: {
                fontColor: "white",
                fontSize: 14,
                beginAtZero: true,
              },
              gridLines: {
                drawBorder: true,
                display: false
              }

            }],
            xAxes: [{
              ticks: {
                fontColor: "white",
                fontSize: 14,
              },
              gridLines: {
                drawBorder: true,
                display: false
              }
            }]
          },
          tooltips: {
            displayColors: false,
            rtl: true,
            backgroundColor: 'white',
            titleAlign: 'center',
            bodyAlign: 'center',
            titleFontColor: 'blue',
            bodyFontColor: 'blue'

          },

        }
    );
  }
};
</script>
