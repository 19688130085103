<template>
  <v-container
      id="dashboard-view"
      fluid
      tag="section"
  >

    <v-row>

      <v-col
          v-for="({ actionIcon, actionText, ...attrs }, i) in stats"
          :key="i"
          cols="12"
          lg="3"
          md="3"
      >
        <material-stats-card v-bind="attrs"/>

      </v-col>

      <v-col
          v-for="(chart, i) in charts"
          :key="`chart-${i}`"
          cols="12"
          lg="6"
          md="6"
      >
        <material-chart-card
            :key="chartKey"
            :color="chart.color"
            :data="chart.info.data"
            :labels="chart.info.labels"
            :title="chart.title"
            :type="chart.type">

          <template #subtitle>

            <div class="font-weight-light text--secondary text-center">
              <p>{{ chart.info.totalData }} {{ chart.subtitle }}</p>
            </div>
          </template>


        </material-chart-card>
      </v-col>


    </v-row>
  </v-container>
</template>

<script>

import MaterialStatsCard from "@/components/MaterialStatsCard.vue";
import MaterialChartCard from "@/components/MaterialChartCard.vue";
import networks from '../services/networks';
import {urlStatistics} from "@/config";

export default {
  name: 'Home',
  components: {MaterialStatsCard, MaterialChartCard},

  data: () => ({
    charts: [
      {
        type: 'Line',
        color: 'info',
        title: 'Monthly orders',
        info: {},
        subtitle: "based on months"
      },
      {
        type: 'Bar',
        color: '#3048a8',
        title: 'Orders statuses',
        info: {},
        subtitle: "based on statuses"
      },
    ],
    chartKey: 0,
    stats: [
      {
        color: 'warning',
        icon: 'mdi-view-dashboard-outline',
        title: 'Number of Staff',
        value: '0',
      },
      {
        color: '#677b89',
        icon: 'mdi-account-star-outline',
        title: "Number of Engineers",
        value: '0',
      },
      {
        color: 'success',
        icon: 'mdi-set-left-center',
        title: "Number of Customers",
        value: '0',
      },
      {
        color: 'error',
        icon: 'mdi-counter',
        title: "Number of Admins",
        value: '0',
      },
    ],

  }),

  created() {
    this.getReportsFromApi();
  },

  methods: {
    getReportsFromApi() {
      networks.fetchFromWeb(urlStatistics)
          .then(response => {

            const users = response.data.users;
            users.forEach(user => {

              if (user["Roles.slug"] === "admin") {
                this.stats[3].value = user.count.toString();
              } else if (user["Roles.slug"] === "engineer") {
                this.stats[1].value = user.count.toString();
              } else if (user["Roles.slug"] === "customer") {
                this.stats[2].value = user.count.toString();
              } else if (user["Roles.slug"] === "staff") {
                this.stats[0].value = user.count.toString();
              }

            });

            const ordersStatus = response.data.ordersStatus;
            let infoLabels = [], infoData = [];
            ordersStatus.forEach(order => {
              infoLabels.push(order.status);
              infoData.push(order.count);
            });
            this.charts[1].info = {labels: this.convertStatusNumbersToNames(infoLabels), data: infoData};

            const ordersMonth = response.data.ordersMonth;
            infoLabels = [];
            infoData = [];
            ordersMonth.forEach(order => {
              infoLabels.push(order.month);
              infoData.push(order.count);
            });
            this.charts[0].info = {labels: this.convertMonthNumbersToNames(infoLabels), data: infoData};
            this.chartKey++;

          })
          .catch(error => {
            alert(error);
          });
    },

    convertMonthNumbersToNames(monthNumbers) {
      const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
      return monthNumbers.map(number => months[number - 1]);
    },

    convertStatusNumbersToNames(statusNumbers) {
      const status = ['Pending', 'Completed', 'Canceled', 'In Process', 'Re-Call'];
      return statusNumbers.map(number => status[number]);
    },
  }


}
</script>