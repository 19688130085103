<template>
  <material-card
      class="v-card--material-stats  mb-4"
      v-bind="$attrs"
      v-on="$listeners"
  >
    <template #subtitle>
      <div
          class="text-center text-h4 v-card__subtitle--material-stats  mb-3"
          v-text="value"
      />
    </template>

    <template #actions>
      <slot name="actions"/>
    </template>

    <slot/>
  </material-card>
</template>

<script>
import MaterialCard from "@/components/MaterialCard.vue";

export default {
  name: 'MaterialStatsCard',
  components: {MaterialCard},

  props: {value: String},
}
</script>

<style lang="sass">
.v-card--material-stats.v-card--material .v-card--material__title
  color: #999999
  font-size: .875rem !important
  margin-left: auto
  text-align: right

.v-card__subtitle--material-stats
  color: #3C4858
</style>
